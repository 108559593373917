import React, { useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { APIEndPoints } from "./APIEndPoints";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getfacilitypermission } from "../redux/action/service";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./Navbar";
import "rsuite/dist/rsuite-no-reset.min.css";
import { DateRangePicker } from "rsuite";

function PatientSurveys() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies();
  const location = useLocation();

  const facility_id = location?.state?.facility_id;
  const surveyType = location?.state?.surveyType;

  const [responseData, setResponseData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const [isSearching, setSearching] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const [dateRange, setDateRange] = useState([]);

  const [lowestDate, setLowestDate] = useState(null);
  const [highestDate, setHighestDate] = useState(null);

  const handleDateChange = (value) => {
    try {
      setDateRange(value);
      // Parse the date strings into JavaScript Date objects
      const [startDateString, endDateString] = value;
      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);

      // Format the dates as "YYYY-MM-DD"
      const formattedStartDate = `${startDate.getFullYear()}-${(
        startDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;
      const formattedEndDate = `${endDate.getFullYear()}-${(
        endDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`;

      handleDateRangefilter(formattedStartDate, formattedEndDate);
    } catch {
      setDateRange([]);
    }
  };

  function handleDateRangefilter(startDate, endDate) {
    setLoading(true);
    const apiUrl = `${APIEndPoints.FACILITY_SURVEY_PATIENTS}${selectedFacility}/${surveyType}/${startDate}/${endDate}/`;
    axios
      .get(apiUrl)
      .then((response) => {
        setResponseData(response.data.survey_list);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  }

  const handleSearchChange = (e) => {
    const searchInput = e.target.value;
    setSearchTerm(searchInput);

    if (searchInput === "") {
      setSearching(false);
      setFilteredData([]);
    } else {
      const filteredData = responseData.filter(
        (item) =>
          item.last_name.toLowerCase().includes(searchInput.toLowerCase()) ||
          item.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
          `${item.last_name.toLowerCase()} ${item.first_name.toLowerCase()}`.includes(
            searchInput.toLowerCase()
          )
      );
      setFilteredData(filteredData);
      setSearching(true);
    }
  };

  const cookie = new Cookies();
  const token = cookie.get("token");

  const dispatch = useDispatch();
  const { servicedata, isLoading, isError } = useSelector(
    (state) => state.service
  );

  useEffect(() => {
    dispatch(getfacilitypermission(token));
  }, [dispatch]);

  useEffect(() => {
    if (servicedata && servicedata.facilities.length > 0) {
      setSelectedFacility(facility_id);
    }
  }, [servicedata]);

  const [selectedFacility, setSelectedFacility] = useState("");

  const handleFacilityChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedFacility(selectedValue);
    if (selectedValue) {
      setLoading(true);
      const apiUrl = `${APIEndPoints.FACILITY_SURVEY_PATIENTS}${selectedValue}/${surveyType}`;
      axios
        .get(apiUrl)
        .then((response) => {
          setResponseData(response.data.survey_list);
          setLowestDate(response.data.lowest_date);
          setHighestDate(response.data.highest_date);
          setDateRange([]);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!facility_id) {
      navigate("/");
    }
  }, [navigate, facility_id]);

  useEffect(() => {
    setLoading(true);
    const apiUrl = `${APIEndPoints.FACILITY_SURVEY_PATIENTS}${facility_id}/${surveyType}`;
    axios
      .get(apiUrl)
      .then((response) => {
        setResponseData(response.data.survey_list);
        setLowestDate(response.data.lowest_date);
        setHighestDate(response.data.highest_date);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  }, []);

  const [unlistedResidents, setUnlistedResidents] = useState([]);
  const [unlistedResidentsSearch, setUnlistedResidentsSearch] = useState("");
  const [fetchingUnlistResidents, setFetchingUnlistResidents] = useState(false);

  const handelUnlistedResidentFetch = async (search) => {
    try {
      setFetchingUnlistResidents(true);
      if (selectedFacility) {
        const res = await axios.get(
          `${APIEndPoints.UNLISTED_RESIDENTS}${selectedFacility}/${search}`
        );
        setUnlistedResidents(res?.data?.data);
        setFetchingUnlistResidents(false);
      }
    } catch (err) {
      console.log(err, "Error");
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setFetchingUnlistResidents(false);
    }
  };


  const handleSearch = (event) => {
    setUnlistedResidentsSearch(event.target.value);
    const search = event.target.value;
    if(search.length === 0){
      setUnlistedResidents([]);
    }
    // Set a new timeout
    let timeout = setTimeout(() => {
      if (search.length > 3) {
        handelUnlistedResidentFetch(search);
      }
    }, 900);
  };

  const createSurvey = async (
    resident_id,
    resident_name,
    resident_client_id
  ) => {
    try {
      const res = await axios.post(APIEndPoints.CREATE_SURVEY, {
        facility_id: selectedFacility,
        survey_type: surveyType,
        resident_id: resident_id,
        resident_name: resident_name,
        resident_client_id: resident_client_id,
      });
      if (res?.status === 200) {
        toast.success("Survey created successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("survey id", res?.data?.survey_id);
        setTimeout(() => {
          navigate(
            surveyType === "Admission"
              ? "/admission-survey-asking"
              : "/discharge-survey-asking",
            {
              state: {
                patientId: res?.data?.survey_id,
                facilityId: selectedFacility,
                patientName: resident_name,
              },
            }
          );
        }, 2000);
      }
    } catch (err) {
      console.log(err, "Error");
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setFetchingUnlistResidents(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid patient-survey-page patient-main-page p-0 m-0">
        <Navbar />
        <div
          className="container patient-container mb-4 mx-auto"
          style={{ marginTop: "15vh" }}
        >
          {loading ? (
            <div
              className="d-flex align-items-center"
              style={{ height: "90vh" }}
            >
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between w-100">
                <div className="patient-survey-select">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search here"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="patient-survey-select">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleFacilityChange}
                    value={selectedFacility}
                  >
                    <option value="">Select facility here</option>

                    {servicedata &&
                      servicedata.facilities.map((facility) => (
                        <option
                          key={facility.id}
                          value={facility.id}
                          defaultValue={facility.id === facility_id}
                        >
                          {facility.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              {!loading && (
                <div className="d-flex justify-content-between mt-4 w-100">
                  <div className="patient-survey-select">
                    <DateRangePicker
                      value={dateRange}
                      onChange={handleDateChange}
                      minDate={lowestDate}
                      maxDate={highestDate}
                      showOneCalendar
                    />
                  </div>
                  <div className="patient-survey-select">
                    <a
                      className="btn text-white border-0"
                      style={{
                        background:
                          "linear-gradient(45deg, #459fff 2.41%, #6de5ff 104.46%)",
                        minWidth: "120px",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      Unlisted resident(s)
                    </a>
                  </div>
                </div>
              )}

              <div className="w-100 my-3">
                <h1 className="text-center mb-3">
                  Patient Surveys - {surveyType}
                </h1>
              </div>
              {isSearching | (searchTerm !== "") ? (
                <div className="row row-cols-auto">
                  {filteredData.length > 0 ? (
                    filteredData.map((item) => (
                      <div
                        key={item.id}
                        className={
                          filteredData.length > 3
                            ? "col-md-3 col-sm-6 col-12 mb-2"
                            : "col-8 offset-2 mb-2"
                        }
                      >
                        <div
                          className="patientbox"
                          onClick={(e) => {
                            navigate(
                              surveyType === "Admission"
                                ? "/admission-survey-asking"
                                : "/discharge-survey-asking",
                              {
                                state: {
                                  patientId: item.id,
                                  facilityId: selectedFacility,
                                  patientName: `${item.last_name} ${item.first_name}`,
                                },
                              }
                            );
                          }}
                        >
                          <p className="mb-0">
                            {item.last_name}, {item.first_name}
                          </p>
                          <p> - {item.due_date}</p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h5>No surveys are due</h5>
                  )}
                </div>
              ) : (
                <div className="row row-cols-auto">
                  {responseData.length > 0 ? (
                    responseData.map((item) => (
                      <div
                        key={item.id}
                        className={
                          responseData.length > 3
                            ? "col-md-3 col-sm-6 col-12 mb-2"
                            : "col-8 offset-2 mb-2"
                        }
                      >
                        <div
                          className="patientbox"
                          onClick={(e) => {
                            navigate(
                              surveyType === "Admission"
                                ? "/admission-survey-asking"
                                : "/discharge-survey-asking",
                              {
                                state: {
                                  patientId: item.id,
                                  facilityId: selectedFacility,
                                  patientName: `${item.last_name} ${item.first_name}`,
                                },
                              }
                            );
                          }}
                        >
                          <p className="mb-0">
                            {item.last_name}, {item.first_name} -{" "}
                            {item.due_date}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h5>No surveys are due</h5>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Create Survey for Unlisted Resident
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                type="text"
                placeholder="Search here"
                value={unlistedResidentsSearch}
                onChange={handleSearch}
              />

              <div className="mt-3 text-center">
                {!fetchingUnlistResidents ? (
                  <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 ">
                    {unlistedResidents?.length > 0 &&
                      unlistedResidents.map((item, index) => (
                        <div key={index} className="col mb-2">
                          <div
                            className="patientboxsearch  p-3 border"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              createSurvey(
                                item?.id,
                                `${item?.last_name} ${item?.first_name}`,
                                item?.client_id
                              );
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{ wordBreak: "break-all" }}
                            >
                              {item?.last_name}, {item.first_name}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}

                {!fetchingUnlistResidents &&
                unlistedResidents?.length === 0 &&
                unlistedResidentsSearch.length > 0 ? (
                  <p>No Data Found</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientSurveys;