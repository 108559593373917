import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIEndPoints } from "../../Components/APIEndPoints";

export const getfacilitypermission = createAsyncThunk(
  "getfacilitypermission",
  async (token) => {
    try {
      const { data } = await axios.get(`${APIEndPoints.FACILITY_PERMISSION}${token.sf_user_id}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);
