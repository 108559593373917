import React, { useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { APIEndPoints } from "./APIEndPoints";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Arrowleft from "../Assets/left-arrow.png";

function EmployeeAcknowledgement() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const [facilityId, setFacilityId] = useState(location?.state?.facilityId);
  const [patientId, setPatientId] = useState(location?.state?.patientId);
  const [patientName, setPatientName] = useState(location?.state?.patientName);
  const [surveyType, setSurveyType] = useState(location?.state?.surveyType);


  const navigate = useNavigate();

  const cookie = new Cookies();
  const token = cookie.get("token");

  const [showDepartment, setShowDepartment] = useState(true);
  const [showBranch, setShowBranch] = useState(false);
  const [showEmployeeList, setShowEmployeeList] = useState(false);
  const [askingForReviewAgain, setAskingForReviewAgain] = useState(false);

  const [department, setDepartment] = useState(null);
  const [employeeRole, setEmployeeRole] = useState(null);

  const [reviewData, setReviewData] = useState([]);

  const [textareaValue, setTextareaValue] = useState("");

  const [employeeName, setEmployeeName] = useState("");
  const [employeeId, setEmployeeId] = useState("");

  const [answerSending, setAnswerSending] = useState(false);

  const surveyAnswers = localStorage.getItem("surveyAnswers");

  const parsedSurveyAnswers = JSON.parse(surveyAnswers);

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  useEffect(() => {
    setLoading(true);
    const apiUrl = `${APIEndPoints.ACTIVE_EMPLOYEE}${facilityId}`;
    axios
      .get(apiUrl)
      .then((response) => {
        setData(response.data);
        console.log(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  }, [facilityId]); // Added dependency array to

  function showEmployeeRoles(category) {
    setDepartment(category);
    setShowDepartment(false);
    setShowBranch(true);
  }

  function showAllEmployee(role) {
    setEmployeeRole(role);
    setShowDepartment(false);
    setShowBranch(false);
    setShowEmployeeList(true);
  }

  const addEmployeeReview = (newEmployee) => {
    setReviewData([...reviewData, newEmployee]);
    setDepartment(null);
    setEmployeeRole(null);
    setEmployeeName(null);
    setEmployeeId(null);
    setTextareaValue(null);
    setShowEmployeeList(false);
    setAskingForReviewAgain(true);
  };

  const submitSurveyAnswers = async () => {
    try {
      setAskingForReviewAgain(false);

      setAnswerSending(true);

      const { sf_user_id } = token;
      const response = await fetch(APIEndPoints.SURVEY_ANSWERS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          survey_answer_list: parsedSurveyAnswers,
          patient_id: patientId,
          sf_user_id: sf_user_id,
          ack_data: reviewData,
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        localStorage.removeItem("surveyAnswers");
        toast.success("Successfully submitted.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          navigate("/print-survey", {
            state : {
              survey_id_list : responseData.survey_id_list,
              surveyType: surveyType,
              patientName: patientName
            }
          });
        }, 1000);
      } else {
        console.error("Failed");
        throw new Error("Something went wrong.");
      }
    } catch (error) {
      console.error("Failed", error);
      toast.error("Something went wrong.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } finally {
      localStorage.removeItem("surveyAnswers");
      setAnswerSending(false);
    }
  };

  function departmentBack() {
    setShowBranch(false);
    setShowDepartment(true);
    setDepartment(null);
  }

  function branchBack() {
    setShowEmployeeList(false);
    setShowBranch(true);
    setEmployeeRole(null);
  }

  return (
    <>
      <ToastContainer />
      <div className="container-fluid patient-survey-page patient-main-page p-0 m-0">
        <Navbar />
        <div
          className="container"
          style={{
            height: "90vh",
            display: "grid",
            placeItems: "center",
            marginTop: "15vh",
          }}
        >
          <div className="acknowledge-box">
            <div className="">
              {loading ? (
                <>
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {showDepartment && (
                    <>
                      <div>
                        <div>
                          <h5>
                            <strong>
                              Question: What department is the employee part of?
                            </strong>
                          </h5>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="row row-cols-auto my-4 employee-review-list">
                            {Object.keys(data).map((category) => (
                              <div className="col mx-2 my-2" key={category}>
                                <button
                                  type="button"
                                  className="btn btn-primary ack-buttons"
                                  value={category}
                                  onClick={() => {
                                    showEmployeeRoles(category);
                                  }}
                                >
                                  {category}
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {showBranch && (
                    <>
                      <div className="ack-que-head">
                        <img
                          src={Arrowleft}
                          className="ack-left-arrow"
                          alt=""
                          srcset=""
                          onClick={() => {
                            departmentBack();
                          }}
                        />
                        <h5>
                          <strong>
                            Question: Which position is the employee part of?
                          </strong>
                        </h5>
                      </div>
                      <div className="row row-cols-auto my-4 employee-review-list">
                        {Object.keys(data[department]).map((role) => (
                          <div className="col my-2" key={role}>
                            <button
                              type="button"
                              className="btn btn-primary ack-buttons"
                              value={role}
                              key={role}
                              onClick={() => {
                                showAllEmployee(role);
                              }}
                            >
                              {role}
                            </button>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {showEmployeeList && (
                    <>
                      <div className="ack-que-head">
                        <img
                          src={Arrowleft}
                          className="ack-left-arrow"
                          alt=""
                          srcset=""
                          onClick={() => {
                            branchBack();
                          }}
                        />
                        <h5>
                          <strong>
                            Question: Which employee would you like to
                            acknowledge?
                          </strong>
                        </h5>
                      </div>
                      <div className="row row-cols-auto my-4 employee-review-list">
                        <div className="col my-2">
                          {data[department][employeeRole].map((name) => (
                            <button
                              type="button"
                              className="btn btn-primary mx-2 my-2 ack-buttons"
                              value={name.split("-")[0]}
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              key={name.split("-")[0]}
                              onClick={() => {
                                setEmployeeName(name.split("-")[0]);
                                setEmployeeId(name.split("-")[1]);
                              }}
                            >
                              {name.split("-")[0]}
                            </button>
                          ))}
                        </div>
                      </div>

                      <div
                        className="modal fade"
                        id="staticBackdrop"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog  modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="staticBackdropLabel"
                              >
                                Add review here
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <div className="form-floating">
                                <textarea
                                  className="form-control"
                                  placeholder="Write review here"
                                  id="floatingTextarea2"
                                  style={{ height: "100px" }}
                                  value={textareaValue}
                                  onChange={handleTextareaChange}
                                ></textarea>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  addEmployeeReview({
                                    id: employeeId,
                                    name: employeeName,
                                    position: employeeRole,
                                    department: department,
                                    comment: textareaValue,
                                  })
                                }
                                data-bs-dismiss="modal"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {askingForReviewAgain && (
                    <>
                      <div>
                        <h5>
                          <strong>
                            Question: Is there any other employee you would like
                            to acknowledge?
                          </strong>
                        </h5>
                      </div>
                      <div className="d-flex justify-content-around">
                        <button
                          type="button"
                          className="btn btn-primary"
                          value="yes"
                          onClick={() => {
                            setAskingForReviewAgain(false);
                            setShowDepartment(true);
                          }}
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          value="no"
                          onClick={() => {
                            submitSurveyAnswers();
                          }}
                        >
                          No
                        </button>
                      </div>
                    </>
                  )}
                  {answerSending && (
                    <>
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeAcknowledgement;
