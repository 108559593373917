import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { APIEndPoints } from "./APIEndPoints";

function ForgetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showCodeBox, setShowCodeBox] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [formData, setFormData] = useState({
    otp: "",
    password: "",
  });
  const [errorResponse, setErrorResponse] = useState("");

  const handleChange = (e) => {
    setErrorResponse("");
    setUserEmail(e.target.value);
  };

  const handleFormChange = (e) => {
    setErrorResponse('');
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userEmail === ''){
      setErrorResponse("Enter email");
      return
    }

    setLoading(true);

    try {
      const response = await axios.get(
        `${APIEndPoints.FORGET_PASSWORD}${userEmail}`
      );
      setUserId(response?.data?.user_id);
      setShowCodeBox(true);
    } catch (err) {
      console.error("Error:", err);
      setErrorResponse(err?.response?.data?.msg);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedFormData = {
      ...formData,
      user_id: userId,
    };

    try {
      const response = await axios.post(
        `${APIEndPoints.FORGET_PASSWORD}`,
        updatedFormData
      );
      toast.success("Password updated Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (err) {
      console.error("Error:", err);
      setErrorResponse(err?.response?.data?.msg);
    } finally {
      setFormData({
        otp: "",
        password: "",
      });
      setLoading(false);
    }
  };

  const handelBackClick = () => {
    // Navigate to the "/forgot-password" page
    navigate("/login");
  };

  const handelEmailShow = () => {
    // Navigate to the "/forgot-password" page
    setShowCodeBox(false);
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid patient-survey-page patient-main-page">
        <div
          className="container-fluid d-flex justify-content-center align-items-center"
          style={{ height: "95vh" }}
        >
          {!showCodeBox ? (
            <div className="d-flex flex-column">
              <div className="box" style={{ padding: "3.5rem" }}>
                <div className="mb-3 text-center">
                  <h3>Forget Password</h3>
                  <hr />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter your email here"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  <div id="emailHelp" className="form-text">
                    We'll never share your email with anyone else.
                  </div>
                </div>
                <div
                  className="d-flex justify-content-center mb-3"
                  style={{ columnGap: "20px" }}
                >
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? "Sending..." : "Send"}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handelBackClick}
                    disabled={loading}
                  >
                    Back
                  </button>
                </div>
                <div className="text-center">
                  {errorResponse ? (
                    <>
                      <p className="text-danger">{errorResponse}</p>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column">
              <div className="box" style={{ padding: "3.5rem" }}>
                <div className="text-center mb-3">
                  <h4>Generate Password</h4>
                  <hr />
                </div>
                <form onSubmit={handleFormSubmit}>
                  <div className="mb-3">
                    <label htmlFor="exampleInputNumber1" className="form-label">
                      Enter OTP
                    </label>
                    <input
                      type="number"
                      name="otp"
                      value={formData.otp}
                      placeholder="Enter OTP here"
                      className="form-control"
                      id="exampleInputNumber1"
                      onChange={(e) => handleFormChange(e)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      placeholder="Enter password"
                      className="form-control"
                      id="exampleInputPassword1"
                      onChange={(e) => handleFormChange(e)}
                      required
                    />
                  </div>
                  <div
                    className="d-flex justify-content-center mb-3"
                    style={{ columnGap: "20px" }}
                  >
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={loading}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handelEmailShow}
                    >
                      Back
                    </button>
                  </div>
                  <div className="text-center">
                    {errorResponse ? (
                      <>
                        <p className="text-danger">{errorResponse}</p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;
