import React, { useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { ToastContainer, toast } from "react-toastify";
import { APIEndPoints } from "./APIEndPoints";
import axios from "axios";

function ResidentDischarged() {
  const location = useLocation();

  const [patientId, setPatientId] = useState(location?.state?.patientId);

  const [loading, setLoading] = useState(false);

  const [modalPara, setModalPara] = useState(location?.state?.para);

  const [reason, setReason] = useState("");

  const handleReasonChange = (event) => {
    const selectedValue = event.target.value;
    setReason(selectedValue);
  };


  const cookie = new Cookies();
  const token = cookie.get("token");

  const navigate = useNavigate();

  const handleButtonClick = (event) => {
    const value = event.target.value;
    if (value === "yes") {
      handelsubmit(event);
    } else {
      navigate("/");
    }
  };

  const handelsubmit = async (e) => {
    e.preventDefault();

    const { jwt_access, jwt_refresh, sf_user_id } = token;

    setLoading(true);

    if (reason === ""){
        toast.error("Select a reason", {
            position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return
    }

    try {
      const res = await axios.post(APIEndPoints.RESIDENT_DISCHARGED, {
        user_id: sf_user_id,
        patientId: patientId,
        reason: reason,
      });
      toast.success("Successfully updated", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (err) {
      console.log(err, "Error");
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid patient-survey-page patient-main-page p-0 m-0">
        <Navbar />

        <div
          className="container-fluid d-flex justify-content-center align-items-center"
          style={{ height: "95vh" }}
        >
          {loading ? (
            <div
              className="d-flex align-items-center"
              style={{ height: "90vh" }}
            >
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="box" style={{ padding: "3.5rem" }}>
                <div className="mb-3">
                    <p className='text-center'>
                        {modalPara}
                    </p>
                </div>
              <div className="mb-4">
                <select
                  class="form-select form-select-sm"
                  aria-label="Select a reason"
                  onChange={handleReasonChange}
                  value={reason}
                  required
                >
                  <option selected>Select a reason</option>
                  <option value="Discharged">Discharged</option>
                  <option value="Out To Hospital">Out To Hospital</option>
                  <option value="Resident Refused">Resident Refused</option>
                  <option value="Left AMA">Left AMA</option>
                </select>
              </div>
              <div className="d-flex justify-content-around">
                <button
                  type="button"
                  className="btn btn-primary"
                  value="yes"
                  onClick={handleButtonClick}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-danger ms-4"
                  value="no"
                  onClick={handleButtonClick}
                >
                  Back
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ResidentDischarged;
