import React, { useEffect, useState } from "react";
import Arrow from "../Assets/Arrow.png";
import ArrowWhite from "../Assets/ArrowWhite.png";
import IconOne from "../Assets/healthcare-hospital-medical-12-svgrepo-com 1.png";
import IconTwo from "../Assets/Group.png";
import IconThree from "../Assets/Icon.png";
import IconFour from "../Assets/healthcare-hospital-medical-5-svgrepo-com 1.png";
import IconFive from "../Assets/healthcare-hospital-medical-43-svgrepo-com 1.png";
import IconSix from "../Assets/healthcare-hospital-medical-33-svgrepo-com 1.png";
import IncentiveIcon from "..//Assets/usericon.png";
import { getfacilitypermission } from "../redux/action/service";
import { useDispatch, useSelector } from "react-redux";
import { Cookies } from "react-cookie";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Logo from "../Assets/LOGO.png";
import Shapes from "../Assets/shapes.png";
import { ToastContainer, toast } from "react-toastify";

function Home() {
  const navigate = useNavigate();

  const cookie = new Cookies();
  const token = cookie.get("token");

  const [allFacilityAccess, setAllFacilityAccess] = useState(
    token["all_facility_access"]
  );
  const [allPermissionAccess, setAllPermissionAccess] = useState(
    token["all_permission_access"]
  );

  const dispatch = useDispatch();
  const { servicedata, isLoading, isError } = useSelector(
    (state) => state.service
  );

  useEffect(() => {
    dispatch(getfacilitypermission(token));
  }, [dispatch]);

  const [cookies, setCookie, removeCookie] = useCookies();
  const handleClearCookie = () => {
    removeCookie("token");
    window.location.href = "/login";
  };

  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (servicedata) {
      const types = servicedata.permissions.map(
        (permission) => permission.type
      );
      setPermissions(types);
    }
  }, [servicedata]);

  useEffect(() => {
    if (servicedata && servicedata.facilities.length > 0) {
      setSelectedFacility(servicedata.facilities[0].id);
    }
  }, [servicedata]);

  const [selectedFacility, setSelectedFacility] = useState("");

  const handleFacilityChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedFacility(selectedValue);
  };

  const handleServiceClick = (e) => {
    e.preventDefault();
    if (!selectedFacility) {
      toast.error("Select facility first", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    navigate("/patient-survey-asking", {
      state: { facility_id: selectedFacility },
    });
  };

  return (
    <>
      <ToastContainer />
      <div
        className="container-fluid p-0 home-page"
        style={{ overflowX: "hidden" }}
      >
        <div className="py-4 px-4 d-flex justify-content-between ">
          <div
            className=""
            style={{ position: "relative", cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={Shapes} alt="" />
            <img src={Logo} alt="" className="ms-2 logo-image" />
          </div>
          <div className="d-flex align-item-center">
            <button className="logout-btn" onClick={handleClearCookie}>
              Logout{" "}
              <i className=" ms-2 fa-solid fa-arrow-right-from-bracket"></i>
            </button>
          </div>
        </div>

        <div className="services" style={{ marginTop: "150px" }}>
          <div className="row">
            <div className="col-10 offset-1 col-md-8 offset-md-2 col-sm-8 offset-sm-2 service-header">
              <div>
                <p
                  className="text-white"
                  style={{ fontSize: "1.5rem", fontWeight: "600" }}
                >
                  Our Services
                </p>
              </div>
              <div className="select-input-service">
                <select
                  className="form-select facility-select"
                  aria-label="Default select example"
                  onChange={handleFacilityChange}
                  value={selectedFacility}
                >
                  <option value="">Select facility here</option>

                  {servicedata &&
                    servicedata.facilities.map((facility) => (
                      <option
                        key={facility.id}
                        value={facility.id}
                        defaultValue={
                          facility.id === servicedata.facilities[0].id
                        }
                      >
                        {facility.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-10 offset-1 col-md-8 offset-md-2 col-sm-8 offset-sm-2 my-3">
              <div className="service-conatiner-box">
                <div className="container">
                  {!servicedata ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row">
                        {permissions.includes("Managed_Care") ||
                        allPermissionAccess === true ? (
                          <div className="col service-box service-box-active">
                            <div className="d-flex align-items-center justify-content-center ">
                              <img src={IconTwo} alt="" />
                              <span className="ms-2">Managed Care Updates</span>
                            </div>
                            <div className="arrow-image">
                              <img src={ArrowWhite} alt="" />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {permissions.includes("Patients_Surveys") ||
                        allPermissionAccess === true ? (
                          <div
                            className="col service-box"
                            onClick={(e) => {
                              handleServiceClick(e);
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-center ">
                              <img src={IconOne} alt="" />
                              <span className="ms-2">Patient Surveys</span>
                            </div>
                            <div className="arrow-image">
                              <img src={Arrow} alt="" />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {permissions.includes("Incentive_Acknowledgement") ||
                        allPermissionAccess === true ? (
                          <div
                            className="col service-box"
                            onClick={(e) => {
                              navigate("/acknowledgement-list",{
                                state: { facility_id: selectedFacility },
                              });
                            }}
                          >
                            <div className="d-flex align-items-center justify-content-center ">
                              <img src={IncentiveIcon} alt="" />
                              <span className="ms-2">
                                Incentive Acknowledgement
                              </span>
                            </div>
                            <div className="arrow-image">
                              <img src={Arrow} alt="" />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {permissions.includes("Social_Work") ||
                        allPermissionAccess === true ? (
                          <div className="col service-box">
                            <div className="d-flex align-items-center justify-content-center">
                              <img src={IconFour} alt="" />
                              <span className="ms-2">Social Work</span>
                            </div>
                            <div className="arrow-image">
                              <img src={Arrow} alt="" />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )}
                </div>

                <div className="container">
                  <div className="row">
                    {permissions.includes("Lorem_ipsum_text") && (
                      <div className="col service-box">
                        <div className="d-flex align-items-center justify-content-center">
                          <img src={IconFive} alt="" />
                          <span className="ms-2">Lorem ipsum text</span>
                        </div>
                        <div>
                          <img src={Arrow} alt="" />
                        </div>
                      </div>
                    )}

                    {permissions.includes("Lorem_ipsum_text") && (
                      <div className="col service-box">
                        <div className="d-flex align-items-center justify-content-center">
                          <img src={IconSix} alt="" />
                          <span className="ms-2">Lorem ipsum text</span>
                        </div>
                        <div>
                          <img src={Arrow} alt="" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
