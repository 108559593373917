import { createSlice } from "@reduxjs/toolkit";
import { getfacilitypermission } from "../action/service";

const serviceslice = createSlice({
  name: "filter",
  initialState: { isLoading: false, servicedata: null, isError: false },
  extraReducers: (builder) => {
    builder.addCase(getfacilitypermission.fulfilled, (state, action) => {
      state.servicedata = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getfacilitypermission.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getfacilitypermission.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});
export default serviceslice.reducer;
