import React, { useEffect, useState } from 'react';
import LoginBanner from '../Assets/healthcare.png';
import UserIcon from '../Assets/usericon.png';
import ViewPass from '../Assets/visualizaricon.png';
import Google from '../Assets/google.png';
import Apple from '../Assets/apple.png';
import Facebook from '../Assets/facebook.png';
import Line from '../Assets/line.png'
import Logo from '../Assets/LOGO.png';
import Shapes from '../Assets/shapes.png';
import axios from "axios";
import { Cookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { APIEndPoints } from './APIEndPoints';


function Login() {

    const [loading, setLoading] = useState(false);

    const cookie = new Cookies();
    const token = cookie.get("token");

    const [loginData, setLoginData] = useState({
        username: "",
        password: "",
    });

    const [errorEmail, setErrorEmail] = useState('');
    const [errorPass, setErrorPass] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handelchange = (e) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate("/");
        }
    }, [token]);

    const login = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (!loginData.username.length) {
            setErrorEmail("Email is required.");
        }

        if (!loginData.password.length) {
            setErrorPass("Password is required.");
        }

        if (!loginData.username.length || !loginData.password.length) {
            setLoading(false);
            return
        }

        await axios.post(APIEndPoints.LOGIN, loginData).then((res) => {
            cookie.set("token", JSON.stringify(res.data), {
                expires: new Date(Date.now() + 604800000),
            });
            setLoading(false);
            navigate("/");
        }).catch((err) => {
            console.log(err, "Error");
            toast.error(err.response?.data?.detail, { position: toast.POSITION.TOP_RIGHT });
        }).finally(() => {
            setLoading(false);
        });

    }

    const handleForgetPasswordClick = () => {
        // Navigate to the "/forgot-password" page
        navigate("/forgot-password");
      };


    return (
        <>
            <ToastContainer />
            <div className=''>
                <div className="container-fluid login-main-page login-main-page-container">
                    <div className="row d-flex justify-content-center align-items-center  my-5">
                        <div className="text-center mb-4 login-mobile-logo">
                            <img src={Shapes} alt="" />
                            <img src={Logo} style={{width:'100px',height:'50px'}} alt="" className='ms-2' />
                        </div>
                        <div className="text-center mb-4">
                            <p style={{ fontSize: '30px', fontWeight: '600', color: '#1261A0' }}>Hi,<span style={{ color: '#72D5EB' }}>Welcome!</span></p>
                        </div>
                        <div className="col-md-9 col-lg-6 col-xl-5 image-box-login">
                            <img className='img-fluid' src={LoginBanner} alt="login-logo" />
                        </div>
                        <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                            <form className='box' onSubmit={login}>
                                <div className="mb-3 text-center">
                                    <p style={{ fontSize: '22px', fontWeight: '600' }}>Log In</p>
                                </div>
                                <div className="mb-3">
                                    <div className="input-container font-new">
                                        <input type="email" name="username" placeholder='Enter email' className="form-control" id="exampleInputEmail1" value={loginData.username} onChange={(e) => {
                                            setErrorEmail("");
                                            handelchange(e);
                                        }} />
                                        <img src={UserIcon} alt="" className="icon"  style={{cursor: 'pointer'}} />
                                    </div>
                                    { errorEmail ? (<p className='text-danger mt-1'>{errorEmail}</p>) : ("") }
                                </div>
                                <div className="mb-3">
                                    <div className="input-container font-new">

                                        <input type={showPassword ? 'text' : 'password'} name="password" placeholder='Enter password' className="form-control" id="exampleInputPassword1" value={loginData.password} onChange={(e) => {
                                            setErrorPass("");
                                            handelchange(e);
                                        }} />
                                        <img src={ViewPass} alt="" className="icon"  style={{cursor: 'pointer'}} onClick={handleTogglePassword} />
                                    </div>
                                    { errorPass ? (<p className='text-danger mt-1'>{errorPass}</p>) : ("") }
                                </div>
                                <div className="mb-3 text-end">
                                    <p className='font-new' style={{cursor: 'pointer'}} onClick={handleForgetPasswordClick}>Forget password ?</p>
                                </div>
                                <div className="mb-3 text-center">
                                    <button type="submit" className='login-btn'>
                                        {loading ? (<div className="text-center">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>) : ("LOGIN")}
                                    </button>
                                </div>
                                <div className="mb-3 text-center">
                                    <div className='d-flex justify-content-center align-items-baseline'>
                                        <img src={Line} style={{ width: '15%' }} alt="" />
                                        <p className='font-new'>or sign up with</p>
                                        <img src={Line} alt="" style={{ width: '15%' }} />
                                    </div>
                                </div>
                                <div className="mb-3 px-4 row">
                                    <div className="col text-center box-icon mx-4">
                                        <img src={Google} className="img-fluid" alt="" />
                                    </div>
                                    <div className="col text-center box-icon mx-4">
                                        <img src={Facebook} className="img-fluid" alt="" />
                                    </div>
                                    <div className="col text-center box-icon mx-4">
                                        <img src={Apple} className="img-fluid" alt="" />
                                    </div>
                                </div>
                                <div className="my-3 mx-4 text-center">
                                    <p className='font-new' style={{ fontSize: '13px' }}><u>Register</u>, if you don't have an account</p>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login