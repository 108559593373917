import React, { useState } from 'react';
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';


function DischargeSurveyButtons() {

    const location = useLocation();
    const [patientId, setPatientId] = useState(location?.state?.patientId);
    const [facilityId, setFacilityId] = useState(location?.state?.facilityId);
    const [patientName, setPatientName] = useState(location?.state?.patientName);

    const [cookies, setCookie, removeCookie] = useCookies();
    const handleClearCookie = () => {
        removeCookie("token");
        window.location.href = "/login";
    };

    const navigate = useNavigate();

    const handleButtonClick = (event) => {
        const value = event.target.value
        if (value === "StartSurvey"){
            navigate('/survey-question-asking', {state : {patientId:patientId, patientName:patientName ,surveyType: "Discharge", facilityId: facilityId}});
        }
        if (value === "DelaySurvey"){
            navigate('/delay-survey', {state : {patientId:patientId, surveyType: "Discharge", facilityId: facilityId}});
        }
        if (value === "ResidentDischarged"){
            navigate('/resident-discharged', {state : {patientId:patientId, para: "Are you sure ?"}});
        }
    };

    return (
        <>  
            <div className="container-fluid patient-survey-page patient-main-page p-0 m-0">
                <Navbar />

                <div className='container-fluid d-flex justify-content-center align-items-center' style={{ height: '95vh' }}>
                    <div className="box row g-3" style={{ padding: '4rem' }}>
                        <div className='col'>
                            <button type="button" className="btn btn-primary bg-full" value="StartSurvey" onClick={handleButtonClick}>Start Survey</button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-info text-white bg-full" value="DelaySurvey" onClick={handleButtonClick}>Delay Survey</button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-secondary bg-full" value="ResidentDischarged" onClick={handleButtonClick}>Can't Complete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DischargeSurveyButtons;
