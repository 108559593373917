import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './Components/Login';
import Home from './Components/Home';
import PrivateComponent from './Components/PrivateComponent';
import PatientSurveys from './Components/PatientSurveys';
import SurveyQuestion from './Components/SurveyQuestion';
import SurveyQuestionAsking from './Components/SurveyQuestionAsking';
import PatientSurveyAsking from './Components/PatientSurveyAsking';
import DischargeSurveyButtons from './Components/DischargeSurveyButtons';
import DelaySurvey from './Components/DelaySurvey';
import ResidentDischarged from './Components/ResidentDischarged';
import AdmissionSurveyButtons from './Components/AdmissionSurveyButtons';
import EmployeeAcknowledgement from './Components/EmployeeAcknowledgement';
import AcknowledgeList from './Components/AcknowledgeList';
import ForgetPassword from './Components/ForgetPassword';
import SurveyCompletionAndPrint from './Components/SurveyCompletionAndPrint';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={'/login'} element={<Login />} />
        <Route exact path={'forgot-password'} element={<ForgetPassword />} />
        < Route element={<PrivateComponent />}>
          <Route exact path={'/'} element={<Home />} />
          <Route exact path={'/patient-survey-asking'} element={<PatientSurveyAsking />} />
          <Route exact path={'/patient-survey'} element={<PatientSurveys />} />
          <Route exact path={'/survey-question-asking'} element={<SurveyQuestionAsking />} />
          <Route exact path={'/survey-question'} element={<SurveyQuestion />} />
          <Route exact path={'/discharge-survey-asking'} element={<DischargeSurveyButtons />} />
          <Route exact path={'/admission-survey-asking'} element={<AdmissionSurveyButtons />} />
          <Route exact path={'/delay-survey'} element={<DelaySurvey />} />
          <Route exact path={'/resident-discharged'} element={<ResidentDischarged />} />
          <Route exact path={'/employee-review'} element={<EmployeeAcknowledgement />} />
          <Route exact path={'/acknowledgement-list'} element={<AcknowledgeList />} />
          <Route exact path={'/print-survey'} element={<SurveyCompletionAndPrint />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
