import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { APIEndPoints } from "./APIEndPoints";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Cookies } from "react-cookie";

function AcknowledgeList() {
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const navigate = useNavigate();

  const location = useLocation();
  const facility_id = location?.state?.facility_id;

  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const cookie = new Cookies();
  const token = cookie.get("token");

  const handleEmployeeClick = (employee) => {
    setSelectedEmployee(employee);
  };

  const handelback = () => {
    setSelectedEmployee(null);
  };

  const handleReviewClick = (id, type) => {
    setLoading(true);
    setSelectedEmployee(null);
    const { sf_user_id } = token;
    const apiUrl = `${APIEndPoints.INCENTIVE_ACKNOWLEDGEMENTS}${facility_id}`;

    axios
      .post(apiUrl, { user_id: sf_user_id, result: type, id: id })
      .then((response) => {
        toast.success(`Successfully ${type}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchData();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${APIEndPoints.INCENTIVE_ACKNOWLEDGEMENTS}${facility_id}`);
      setResponseData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="container-fluid d-flex patient-survey-page patient-main-page p-0 m-0">
        <Navbar />
        <div
          className="container col-10 offset-1 col-md-8 offset-md-2 col-sm-8 offset-sm-2"
          style={{ height: "85vh", marginTop: '15vh' }}
        >
          {!selectedEmployee && !loading && (
            <>
              { responseData.length > 0 ? (
              <div>
                <h2 className="text-center mb-3">
                  <strong>Employee Acknowledgement List</strong>
                </h2>
                <div className="card">
                  <div className="card-body">
                    <div className="row row-cols-1 row-cols-md-4 g-3">
                      { responseData.map((employee) => (
                        <div key={employee.name} className="col mb-3">
                          <button
                            type="button"
                            className="btn btn-primary position-relative w-100 h-100"
                            onClick={() => handleEmployeeClick(employee)}
                          >
                            {employee.name}
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                              {employee.reviews.length}
                            </span>
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              ) : (
                <div className="card">
                  <div className="card-body">
                    <p className="text-center">No data found</p>
                  </div>
                </div>)}
            </>
          )}

          {loading && (
            <>
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          )}

          {selectedEmployee && (
            <>
              <div className="d-flex mt-4 flex-column">
                <p style={{ cursor: "pointer" }} onClick={handelback}>
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                </p>
                <h5 className="text-center mb-3">
                  <strong>{selectedEmployee.name}'s Reviews</strong>
                </h5>
                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th scope="col">Patient Name</th>
                        <th scope="col">Survey Date</th>
                        <th scope="col">Review</th>
                        <th scope="col" colSpan="2" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedEmployee.reviews.map((review) => (
                        <tr key={review.id}>
                          <td>{review.patient_name ? review.patient_name : 'N/A'}</td>
                          <td>{review.survey_date ? review.survey_date.split('T')[0] : 'N/A' }</td>
                          <td>{review.review}</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={() =>
                                handleReviewClick(review.id, "Approved")
                              }
                            >
                              Approve
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() =>
                                handleReviewClick(review.id, "Denied")
                              }
                            >
                              Deny
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default AcknowledgeList;
