import React, { useState } from 'react';
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import { ToastContainer, toast } from "react-toastify";
import { APIEndPoints } from './APIEndPoints';
import axios from "axios";

function DelaySurvey() {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState();
    const [patientId, setPatientId] = useState(location?.state?.patientId);
    const [surveyType, setSurveyType] = useState(location?.state?.surveyType);
    const [facilityId, setFacilityId] = useState(location?.state?.facilityId);

    const [cookies, setCookie, removeCookie] = useCookies();
    const handleClearCookie = () => {
        removeCookie("token");
        window.location.href = "/login";
    };

    const navigate = useNavigate();

    const handleDateInput = (event) => {
        const value = event.target.value;
        setDate(value);
    };

    const handelsubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (date === '') {
            toast.error("Please select a date", { position: toast.POSITION.TOP_RIGHT });
            setDate('');
            setLoading(false);
            return
        }

        try {
            const res = await axios.post(APIEndPoints.DELAY_SURVEY_DATE, { "date": date, "patientId": patientId });
            setLoading(false);
            setDate('');
            toast.success("Successfully Saved", { position: toast.POSITION.TOP_RIGHT });
            setTimeout(() => {
                navigate('/patient-survey',{
                    state : {
                        surveyType: surveyType,
                        facility_id: facilityId
                    }
                })
            }, 2000);
        } catch (err) {
            console.log(err, "Error");
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="container-fluid patient-survey-page patient-main-page p-0 m-0">
                <Navbar />
                <div className='container-fluid d-flex justify-content-center align-items-center' style={{ height: '95vh' }}>
                    <div className="box" style={{ padding: '3rem 5rem' }}>
                        <form onSubmit={handelsubmit}>
                            <div className='text-center'>
                                <label htmlFor="date" style={{fontSize: '1.2rem'}}>Select Delay Date</label>
                                <input className='form-control mt-2' type="date" name="" id="" value={date} onChange={handleDateInput} />
                            </div>
                            <div className='text-center my-3'>
                                <button type="submit" className='btn text-white' style={{ background: 'linear-gradient(45deg, #459fff 2.41%, #6de5ff 104.46%)', border: 'none' }}>
                                    {loading ?(<span>
                                    Saving.. {' '}
                                    <div className="spinner-border spinner-border-sm" role="status"></div>
                                </span>) : ("Save")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DelaySurvey;
