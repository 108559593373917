import React, { useState } from 'react';
import { Cookies } from "react-cookie";
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';

function AdmissionSurveyButtons() {

    const location = useLocation();

    const [patientId, setPatientId] = useState(location?.state?.patientId);
    const [facilityId, setFacilityId] = useState(location?.state?.facilityId);
    const [patientName, setPatientName] = useState(location?.state?.patientName);

    console.log(patientName);

    const [loading, setLoading] = useState(false);

    const cookie = new Cookies();
    const token = cookie.get("token");

    const navigate = useNavigate();

    const handleButtonClick = (event) => {
        const value = event.target.value
        if (value === "StartSurvey") {
            navigate('/survey-question-asking', {state : {patientId:patientId, patientName:patientName, surveyType: "Admission", facilityId: facilityId}});
        } 
        if( value==="PatientDischarged") {
            navigate('/resident-discharged', {state : {patientId:patientId, para: "Are you sure you want to set this patient to discharged?"}});
        }
    };

    return (
        <>
            <div className="container-fluid patient-survey-page patient-main-page p-0 m-0">

                <Navbar />

                <div className='container-fluid d-flex justify-content-center align-items-center' style={{ height: '95vh' }}>
                    <div className="box" style={{ padding: '3.5rem' }}>
                        <div className='d-flex justify-content-around'>
                            <button type="button" className="btn btn-primary" value="StartSurvey" onClick={handleButtonClick}>Start Survey</button>
                            <button type="button" className="btn btn-danger ms-4" value="PatientDischarged" onClick={handleButtonClick}>Can't Complete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdmissionSurveyButtons;
