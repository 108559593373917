import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { APIEndPoints } from "./APIEndPoints";

function SurveyCompletionAndPrint() {
    const location = useLocation();
    const navigate = useNavigate();
    const [showReturnButtons, setShowReturnButtons] = useState(true);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [surveyIdList, setSurveyIdList] =  useState(location?.state?.survey_id_list)
    const [patientName, setPatientName] = useState(location?.state?.patientName);
    const [surveyType, setSurveyType] = useState(location?.state?.surveyType);


    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.post(APIEndPoints.FETCH_SURVEY_SUBMITTED_ANSWERS, {
                survey_id_list: surveyIdList,
            });
            setData(response.data.data);
            setShowReturnButtons(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setLoading(false);
    };

    const handlePrintButtonClick = () => {
        fetchData();
    };

    return (
        <div className="container-fluid patient-survey-page patient-main-page p-0 m-0">
            <Navbar />
            {loading ? (
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "90vh" }}
                >
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : showReturnButtons ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "95vh" }}>
                    <div className="box" style={{ padding: "3.5rem" }}>
                        <p>Thank you for completing the survey.</p>
                        <div className="d-flex justify-content-around">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handlePrintButtonClick}
                            >
                                Print
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => navigate("/")}
                            >
                                Return Home
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container patient-container" style={{ height: "80vh", marginTop: "15vh" }}>
                    <div className="box" style={{ padding: "3.5rem" }}>
                        <h3 className="text-center mb-4">{patientName} - {surveyType} Survey</h3>
                        <h3 className="text-center mb-4">Submitted Survey Question</h3>
                        {data.map((item, index) => (
                            <div key={index}>
                                <p><strong>Question :</strong> {item.question}</p>
                                <p><strong>Answer :</strong> {item.answer}</p>
                            </div>
                        ))}
                        <div className="text-center mt-4">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => navigate("/")}
                            >
                                Return Home
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default SurveyCompletionAndPrint;
