import React, { useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { APIEndPoints } from "./APIEndPoints";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";

function SurveyQuestion() {
  const location = useLocation();

  const [patientId, setPatientId] = useState(location?.state?.patientId);
  const [patientName, setPatientName] = useState(location?.state?.patientName);

  const [surveyType, setSurveyType] = useState(location?.state?.surveyType);

  const [facilityId, setFacilityId] = useState(location?.state?.facilityId);

  const [showReturnButtons, setShowReturnButtons] = useState(false);

  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies();
  const handleClearCookie = () => {
    removeCookie("token");
    window.location.href = "/login";
  };

  const cookie = new Cookies();
  const token = cookie.get("token");

  const [questionsData, setQuestionsData] = useState([]);

  const [surveyCompleted, setSurveyCompleted] = useState(false);

  const [loading, setLoading] = useState(false);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [updatedIndex, setUpdatedIndex] = useState(0);
  const [isQuestionLoading, setIsQuestionLoading] = useState(false);

  const [answerSending, setAnswerSending] = useState(false);

  useEffect(() => {
    localStorage.removeItem("ans_list");
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${APIEndPoints.SURVEY_QUESTIONS}${surveyType}/`
        );
        setQuestionsData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [prev_Answers, setprev_Answers] = useState({});
  function handleAnswerChange(questionId, answerText) {
    const newSurveyAnswer = {
      Patient_Survey__c: patientId,
      Survey_Question__c: questionId,
      Answer__c: answerText,
    };

    // const filteredAnswers = surveyAnswers.filter(
    //   (answer) => answer.Survey_Question__c !== questionId
    // );
    const filteredAnswers = surveyAnswers.filter(
      (answer) => answer.Survey_Question__c !== questionId
    );
    const filtereduserAnswers = userAnswers.filter(
      (answer) => answer.Survey_Question__c !== questionId
    );
    setSurveyAnswers([...filteredAnswers, newSurveyAnswer]);
    setUserAnswers([...filtereduserAnswers, newSurveyAnswer]);
    localStorage.setItem(
      "ans_list",
      JSON.stringify([...filteredAnswers, newSurveyAnswer])
    );
  }
  const [userAnswers, setUserAnswers] = useState([]);

  const [radio, setradio] = useState("");
  const [text, settext] = useState("");
  const [select, setselect] = useState("");
  function renderQuestion(question) {
    if (!question) {
      return null;
    }

    if (question.type === "Text") {
      return (
        <div>
          <textarea
            type="text"
            className="form-control"
            value={text}
            onChange={(e) => {
              settext(e.target.value);
              setnext(true);
              handleAnswerChange(question.id, e.target.value);
            }}
            placeholder="Enter here ..."
            cols={40}
            rows={5}
            required
          ></textarea>
        </div>
      );
    } else if (question.type === "Radio") {
      const values = question.values;
      return (
        <div>
          {Object.keys(values).map((key, index) => (
            <div key={index} className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name={`radio${index}`}
                // value={userAnswers[key]}
                id={key}
                onChange={(e) => {
                  setnext(true);

                  setradio(true);
                  handleAnswerChange(question.id, key);
                  if (values[key] === null) {
                    setUpdatedIndex(currentQuestionIndex + 1);
                  } else {
                    setUpdatedIndex(
                      questionsData.findIndex((q) => q.id === values[key])
                    );
                  }
                }}
                required
              />
              <label
                className="form-check-label"
                htmlFor={`picklistRadio${index}`}
              >
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </label>
            </div>
          ))}
        </div>
      );
    } else if (question.type === "Picklist") {
      const values = question.values;
      return (
        <div>
          <select
            className="form-select"
            value={select}
            onChange={(e) => {
              setnext(true);
              if (e.target.value === "") {
                return;
              }
              setselect(e.target.value);
              if (e.target.value !== "") {
                handleAnswerChange(question.id, e.target.value);
              }
              const chosenValueId = values[e.target.value];
              if (chosenValueId === null) {
                setUpdatedIndex(currentQuestionIndex + 1);
              } else {
                setUpdatedIndex(
                  questionsData.findIndex((q) => q.id === chosenValueId)
                );
              }
            }}
          >
            <option value="">Select an answer</option>
            {Object.keys(values).map((option, index) => (
              <option key={index} value={option}>
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </option>
            ))}
          </select>
        </div>
      );
    } else if (question.type === "MultiPicklist") {
      const values = question.values;
      
      return (
        <div>
          <label>{question.name}</label>
          {Object.keys(values).map((option, index) => (
            <div key={index} className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="picklistRadio"
                id={`picklistRadio${index}`}
                onChange={() => {
                  setnext(true);
                  handleAnswerChange(question.id, option);
                }}
                required
              />
              <label
                className="form-check-label"
                htmlFor={`picklistRadio${index}`}
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </label>
            </div>
          ))}
        </div>
      );
    } else if (question.type === "Number") {
      return (
        <div>
          <input
            type="number"
            onChange={(e) => {
              setnext(true);
              handleAnswerChange(question.id, e.target.value);
              handleNumberChange(e.target.value);
            }}
            placeholder="Enter here ..."
            className="form-control"
            required
          />
        </div>
      );
    }
  }

  function handleNumberChange(inputValue) {
    const value = parseInt(inputValue);
    const validationDict = currentQuestion.validation_logic;
    const checkValue = validationDict.value;
    const lessThan = validationDict.less;
    const greaterThan = validationDict.greater;
    const equal = validationDict.equal;
    if (value < checkValue) {
      setUpdatedIndex(questionsData.findIndex((q) => q.id === lessThan));
    }
    if (value > checkValue) {
      setUpdatedIndex(questionsData.findIndex((q) => q.id === greaterThan));
    }
    if (value === checkValue) {
      setUpdatedIndex(questionsData.findIndex((q) => q.id === equal));
    }
  }

  const [next, setnext] = useState(false);
  useEffect(() => {
    if (next) {
      setnext(false);
    }
  }, [next]);

  function handleNextQuestion() {
    settext("");
    setselect("");
    setprev_Answers(null);
    // setcurrent_ans_index((p)=>p+1)
    if (updatedIndex !== 0) {
      setIsQuestionLoading(true);
      setTimeout(() => {
        setCurrentQuestionIndex(
          updatedIndex > -1 ? updatedIndex : currentQuestionIndex + 1
        );
        setUpdatedIndex(0);
        setIsQuestionLoading(false);
      }, 200);
      if (currentQuestionIndex === questionsData.length - 1) {
        const currentQuestionId = currentQuestion.id;
        const currentQuestionRequired =
          currentQuestion.validation_logic.required;

        if (currentQuestionRequired) {
          if (
            !userAnswers.some(
              (answer) => answer.Survey_Question__c === currentQuestionId
            )
          ) {
            // Handle the case where the question is not answered
            toast.warning("Please answer the question to proceed.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            return;
          }
        } else {
          const filteredAnswers = userAnswers.filter(
            (answer) => answer.Survey_Question__c === currentQuestionId
          );
          if (!filteredAnswers.length) {
            handleAnswerChange(currentQuestionId, null);
          }
        }

        setSurveyCompleted(true);

        // submitSurveyAnswers();
      }
    } else if (currentQuestionIndex < questionsData.length - 1) {
      // Check if the current question's ID is in userAnswers
      const currentQuestionId = currentQuestion.id;
      const currentQuestionRequired = currentQuestion.validation_logic.required;

      if (currentQuestionRequired) {
        if (
          !userAnswers.some(
            (answer) => answer.Survey_Question__c === currentQuestionId
          )
        ) {
          // Handle the case where the question is not answered
          toast.warning("Please answer the question to proceed.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
      } else {
        const filteredAnswers = userAnswers.filter(
          (answer) => answer.Survey_Question__c === currentQuestionId
        );
        if (!filteredAnswers.length) {
          handleAnswerChange(currentQuestionId, null);
        }
      }

      setIsQuestionLoading(true);
      setTimeout(() => {
        const user_filteredAnswers = userAnswers.filter(
          (answer) => answer.Survey_Question__c === currentQuestion.id
        );
        let new_index;
        if (
          currentQuestion?.values &&
          user_filteredAnswers[0]?.Answer__c &&
          currentQuestion.values[user_filteredAnswers[0]?.Answer__c] !== null
        ) {
          new_index = questionsData.findIndex(
            (q) =>
              q.id === currentQuestion.values[user_filteredAnswers[0].Answer__c]
          );
        } else {
          new_index = currentQuestionIndex + 1;
        }
        setCurrentQuestionIndex(
          new_index > -1 ? new_index : currentQuestionIndex + 1
        );
        // setCurrentQuestionIndex(currentQuestionIndex + 1);
        setIsQuestionLoading(false);
      }, 200);
    } else {
      const currentQuestionId = currentQuestion.id;
      const currentQuestionRequired = currentQuestion.validation_logic.required;

      if (currentQuestionRequired) {
        if (
          !userAnswers.some(
            (answer) => answer.Survey_Question__c === currentQuestionId
          )
        ) {
          // Handle the case where the question is not answered
          toast.warning("Please answer the question to proceed.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
      } else {
        const filteredAnswers = userAnswers.filter(
          (answer) => answer.Survey_Question__c === currentQuestionId
        );
        if (!filteredAnswers.length) {
          handleAnswerChange(currentQuestionId, null);
        }
      }

      setSurveyCompleted(true);
      // submitSurveyAnswers();
    }
    if (!radio) {
      const filteredAnswers = surveyAnswers.filter(
        (answer) => answer.Survey_Question__c !== currentQuestion.id
      );

      const user_filteredAnswers = userAnswers.filter(
        (answer) => answer.Survey_Question__c === currentQuestion.id
      );
      let new_index;
      if (
        currentQuestion?.values &&
        user_filteredAnswers[0]?.Answer__c &&
        currentQuestion.values[user_filteredAnswers[0]?.Answer__c] !== null
      ) {
        new_index = questionsData.findIndex(
          (q) =>
            q.id === currentQuestion.values[user_filteredAnswers[0].Answer__c]
        );
      } else {
        new_index = currentQuestionIndex + 1;
      }
      setCurrentQuestionIndex(
        new_index >= 0 ? new_index : currentQuestionIndex + 1
      );
      setSurveyAnswers([...filteredAnswers, ...user_filteredAnswers]);
    }
  }

  const submitSurveyAnswers = async () => {
    try {
      setAnswerSending(true);
  
      const { sf_user_id } = token;
      const response = await fetch(APIEndPoints.SURVEY_ANSWERS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          survey_answer_list: surveyAnswers,
          patient_id: patientId,
          sf_user_id: sf_user_id,
          ack_data: [],
        }),
      });

      const responseData = await response.json();
  
      if (response.ok) {
        toast.success("Successfully submitted.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        localStorage.removeItem("ans_list");
        setTimeout(() => {
          navigate("/print-survey", {
            state : {
              survey_id_list : responseData.survey_id_list,
              surveyType: surveyType,
              patientName: patientName
            }
          });
        }, 1000);
        setAnswerSending(false);
      } else {
        console.error("Failed");
        throw new Error("Something went wrong.");
      }
    } catch (error) {
      console.error("Failed", error);
      toast.error("Something went wrong.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } finally {
      localStorage.removeItem("ans_list");
      setAnswerSending(false);
    }
  };
  
  const [current_ans_index, setcurrent_ans_index] = useState(0);
  
  function handlePrevQuestion() {
    setradio(false);
    let num = 1;
    if (next === false) {
      num += 1;
    }
    if (currentQuestionIndex > 0) {
      const lastElement =
        surveyAnswers.length > 0
          ? surveyAnswers[surveyAnswers.length - 1]
          : null;
      const lastQuestionId = lastElement && lastElement["Survey_Question__c"];

      let last_ans = surveyAnswers[surveyAnswers.length - 1];
      setprev_Answers(last_ans);
      const updatedList = surveyAnswers.slice(0, surveyAnswers.length - 1);
      setSurveyAnswers(updatedList);
      localStorage.setItem("ans_list", JSON.stringify(updatedList));
      setCurrentQuestionIndex(
        // questionsData.findIndex((q) => q.id === lastQuestionId)
        questionsData.findIndex((q) => q.id === lastQuestionId)
      );
      setIsQuestionLoading(true);
      setTimeout(() => {
        setIsQuestionLoading(false);
      }, 200);
    } else {
      // Alert or handle the case when there are no previous questions
      alert("This is the first question.");
    }
  }

  const currentQuestion = questionsData[currentQuestionIndex];
  useEffect(() => {
    if (currentQuestion) {
      if (currentQuestion.type === "Radio") {
        if (userAnswers) {
          userAnswers.map((it) => {
            if (currentQuestion.id === it?.Survey_Question__c) {
              if (
                document.querySelectorAll(".form-check-input")[0]?.id ===
                it?.Answer__c
              ) {
                document.querySelectorAll(
                  ".form-check-input"
                )[0].checked = true;
                document.querySelectorAll(
                  ".form-check-input"
                )[1].checked = false;
              }
              if (
                document.querySelectorAll(".form-check-input")[1]?.id ===
                it?.Answer__c
              ) {
                document.querySelectorAll(
                  ".form-check-input"
                )[1].checked = true;
                document.querySelectorAll(
                  ".form-check-input"
                )[0].checked = false;
              }
              settext(it?.Answer__c);
              setselect(it?.Answer__c);
              
            }
          });
        }
      }

      if (currentQuestion.type === "Picklist") {
        if (userAnswers) {
          userAnswers.map((it) => {
            if (currentQuestion.id === it?.Survey_Question__c) {
              setselect(it?.Answer__c);
              
            }
          });
        }
      }

      if (currentQuestion.type === "Text") {
        if (userAnswers) {
          userAnswers.map((it) => {
            if (currentQuestion.id === it?.Survey_Question__c) {
              settext(it?.Answer__c);
            }
          });
        }
      }
    }
  }, [
    currentQuestion,
    document.querySelectorAll(".form-check-input"),
    document.querySelector(".form-select"),
  ]);

  return (
    <>
      <ToastContainer />
      <div className="container-fluid patient-survey-page patient-main-page p-0 m-0">
        <Navbar />
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "90vh" }}
          >
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {questionsData.length > 0 ? (
              <>
                <div
                  className="container"
                  style={{
                    height: "90vh",
                    display: "grid",
                    placeItems: "center",
                    marginTop: "30px",
                  }}
                >
                  {loading | isQuestionLoading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="box question-box">
                        <div style={{ padding: "30px" }}>
                          {surveyCompleted ? (
                            <div className="text-center">
                              {answerSending ? (
                                <div className="text-center">
                                  <div className="spinner-border" role="status">
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div>
                                    <h5>
                                      <strong>
                                        Question: Are there any employees you
                                        would like to acknowledge for doing a
                                        good job?
                                      </strong>
                                    </h5>
                                  </div>
                                  <div className="d-flex justify-content-around">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      value="yes"
                                        onClick={() => {
                                        localStorage.removeItem("ans_list");
                                        localStorage.setItem('surveyAnswers', JSON.stringify(surveyAnswers));
                                        navigate('/employee-review',{
                                          state : {facilityId: facilityId, patientId: patientId, surveyType: surveyType, patientName: patientName}
                                        });
                                      }}
                                    >
                                      Yes
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      value="no"
                                      onClick={() => {
                                        submitSurveyAnswers();
                                      }}
                                    >
                                      No
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : (
                            <>
                              <div>
                                <h5>
                                  <strong>
                                    Question: {currentQuestionIndex + 1}
                                  </strong>{" "}
                                  {currentQuestion?.name}
                                </h5>
                              </div>
                              <div className="my-4 d-flex flex-wrap survey-answers">
                                <h5>
                                  <strong>Answers: </strong>{" "}
                                </h5>
                                <div className="ms-3">
                                  {currentQuestion &&
                                    renderQuestion(currentQuestion)}
                                </div>
                              </div>
                              <div className="my-4 d-flex justify-content-between">
                                <button
                                  type="submit"
                                  className="btn next-btn"
                                  onClick={handlePrevQuestion}
                                  disabled={currentQuestionIndex < 1}
                                >
                                  Prev
                                </button>
                                <button
                                  type="submit"
                                  className="btn next-btn"
                                  onClick={handleNextQuestion}
                                >
                                  Next
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  className="container"
                  style={{
                    height: "90vh",
                    display: "grid",
                    placeItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <h3>No survey questions</h3>
                </div>
              </>
            )}{" "}
          </>
        )}
      </div>
    </>
  );
}

export default SurveyQuestion;
