import React from 'react';
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';

function PatientSurveyAsking() {

    const location = useLocation();

    const facility_id = location?.state?.facility_id;


    const [cookies, setCookie, removeCookie] = useCookies();
    const handleClearCookie = () => {
        removeCookie("token");
        window.location.href = "/login";
    };

    const navigate = useNavigate();

    const handleButtonClick = (event) => {
        const value = event.target.value
        navigate('/patient-survey',{
            state : {
                surveyType: value,
                facility_id: facility_id
            }
        })
    };

    return (
        <>
            <div className="container-fluid patient-survey-page patient-main-page p-0 m-0">
                <Navbar />

                <div className='container-fluid d-flex justify-content-center align-items-center' style={{ height: '90vh' }}>
                    <div className="box" style={{ padding: '4rem' }}>
                        <div className='d-flex justify-content-around' style={{ columnGap:'20px' }}>
                            <button type="button" className="btn btn-primary" value="Admission" onClick={handleButtonClick}>Admission Surveys</button>
                            <button type="button" className="btn btn-secondary" value="Discharge" onClick={handleButtonClick}>Discharge Surveys</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PatientSurveyAsking;
