import React, { useState } from 'react';
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';

function SurveyQuestionAsking() {

    const location = useLocation();

    const [patientId, setPatientId] = useState(location?.state?.patientId);
    const [patientName, setPatientName] = useState(location?.state?.patientName);
    const [surveyType, setSurveyType] = useState(location?.state?.surveyType);
    const [facilityId, setFacilityId] = useState(location?.state?.facilityId);


    const [cookies, setCookie, removeCookie] = useCookies();
    const handleClearCookie = () => {
        removeCookie("token");
        window.location.href = "/login";
    };

    const navigate = useNavigate();

    const handleButtonClick = (event) => {
        const value = event.target.value
        if (value === "yes") {
            navigate("/survey-question",{
                state: { patientId: patientId, patientName:patientName, surveyType : surveyType, facilityId: facilityId },
            })
        } else {
            navigate("/")
        }
    };

    return (
        <>
            <div className="container-fluid patient-survey-page patient-main-page p-0 m-0">
                <Navbar />
                <div className='container-fluid d-flex justify-content-center align-items-center' style={{ height: '95vh' }}>
                    <div className="box" style={{ padding: '3.5rem' }}>
                        <p>
                            Do you allow for us to conduct this survey?
                        </p>
                        <div className='d-flex justify-content-around'>
                            <button type="button" className="btn btn-primary" value="yes" onClick={handleButtonClick}>Yes</button>
                            <button type="button" className="btn btn-danger" value="no" onClick={handleButtonClick}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SurveyQuestionAsking;
