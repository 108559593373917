import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useEffect } from "react";
import { Cookies } from "react-cookie";


function PrivateComponent() {
    const cookie = new Cookies();
    const token = cookie.get("token");

  useEffect(() => {}, [token]);
  return token ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateComponent;