import React from 'react';
import Logo from "../Assets/LOGO.png";
import Shapes from "../Assets/shapes.png";
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

function Navbar() {

    const navigate = useNavigate();

    const [cookies, setCookie, removeCookie] = useCookies();
    const handleClearCookie = () => {
        removeCookie("token");
        window.location.href = "/login";
    };

    return (
        <>
            <div className="py-4 px-4 d-flex justify-content-between navbar">
                <div className="" style={{ position: "relative", cursor: 'pointer' }} onClick={() => { navigate("/") }}>
                    <img src={Shapes} alt="" />
                    <img src={Logo} alt="" className="ms-2 logo-image" />
                </div>
                <div className="d-flex align-item-center">
                    <button className="logout-btn" onClick={handleClearCookie}>
                        Logout{" "}
                        <i className=" ms-2 fa-solid fa-arrow-right-from-bracket"></i>
                    </button>
                </div>
            </div>
        </>
    )
}

export default Navbar