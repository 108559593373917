export class APIEndPoints {
  // Replace with your actual API base URL

  static getBaseUrl() {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    console.log(environment);
    switch (environment) {
      case "production":
        return process.env.REACT_APP_PRODUCTION_API_URL;
      case "staging":
        return process.env.REACT_APP_STAGING_API_URL;
      case "local":
        return process.env.REACT_APP_LOCAL_API_URL;
      default:
        throw new Error(`Invalid environment: ${environment}`);
    }
  }

  static BASE_URL = APIEndPoints.getBaseUrl();

  static LOGIN = `${APIEndPoints.BASE_URL}/api/v1/users/sf-login/`;
  static FACILITY_PERMISSION = `${APIEndPoints.BASE_URL}/api/v1/users/facility/permission/`;
  static FACILITY_SURVEY_PATIENTS = `${APIEndPoints.BASE_URL}/api/v1/users/facility/survey/list/`;
  static SURVEY_QUESTIONS = `${APIEndPoints.BASE_URL}/api/v1/users/survey-questions/`;
  static SURVEY_ANSWERS = `${APIEndPoints.BASE_URL}/api/v1/users/survey-answers/`;

  static DELAY_SURVEY_DATE = `${APIEndPoints.BASE_URL}/api/v1/users/delay-survey/`;
  static RESIDENT_DISCHARGED = `${APIEndPoints.BASE_URL}/api/v1/users/resident-discharged/`;

  static ACTIVE_EMPLOYEE = `${APIEndPoints.BASE_URL}/api/v1/users/active/employee/`;

  static ACTIVE_EMPLOYEE = `${APIEndPoints.BASE_URL}/api/v1/users/active/employee/`;

  static INCENTIVE_ACKNOWLEDGEMENTS = `${APIEndPoints.BASE_URL}/api/v1/users/acknowledgement-employee-list/`;

  static FORGET_PASSWORD = `${APIEndPoints.BASE_URL}/api/v1/users/reset-password/`;

  static FETCH_SURVEY_SUBMITTED_ANSWERS = `${APIEndPoints.BASE_URL}/api/v1/users/fetch-question-answers/`;

  static UNLISTED_RESIDENTS = `${APIEndPoints.BASE_URL}/api/v1/users/get-unlisted-patients/`;

  static CREATE_SURVEY = `${APIEndPoints.BASE_URL}/api/v1/users/create-survey/`;

  // Add more endpoints as needed
}
